.block-item
  lost-column 1/1
  padding 1em 0
.block-image
  lost-column 2/8
  padding-top 1em
  +below('s')
    lost-column 1/1
  img
    max-width 100%
.block-content
  lost-column  6/8
  padding-left 2em
  +below('s')
    lost-column 1/1
    padding 0
  h2
    font-weight 400
    margin-top 0em
    margin-bottom 0.2em