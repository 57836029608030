.quicklink
  color secondary-color
  a
    color inherit
    width 100%
    height 100%
    display block
  .quicklink-items
    padding 0
    margin 0
    list-style none
    .quicklink-item
      height 180px
      width 100%
      border 1px solid #eeeeee
      lost-column 1/4 4 10px
      margin 2rem 0
      +below('l')
        lost-column 1/3 3 20px
      +below('m')
        lost-column 1/2 2 10px
      +below('s')
        lost-column 1/1 1
      position relative
      .quicklink-icon
        height 62px
        width 62px
        color #5d5c5c
        border 1px solid #eeeeee
        background #fff
        position absolute
        left -5px
        top 59px
        text-align center
        i
          font-size 2em
          padding-top 0.5em
      .quicklink-content
        padding 2em 2em 0 6em
        font-size 0.8em
        h3
          text-transform uppercase
          font-weight 800
          line-height 2
          margin 0px
      .quicklink-more-container
        text-align center
        width 100%
        position absolute
        bottom 0
      .quicklink-more
        text-transform uppercase
        font-size 0.8rem
        font-weight 500
        background primary-color
        padding 0.1em 1.5em
        color #fff
        position absolute
        left 50%
        bottom 0
        transform: translateX(-50%)

