.contact
  lost-column 1/1
  &--form
    lost-column 7/12
    lost-offset 1/12
    +below('l')
      lost-column 3/4
      lost-offset none
    +below('m')
      lost-column 1/2
    +below('s')
      lost-column 1/1
      margin-right 0px !important
  &--bar
    lost-column 1/4
    +below('m')
      lost-column 1/2
    +below('s')
      lost-column 1/1