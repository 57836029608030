html
    +below('s')
        font-size 90%

body
    font-family $font-family-sans-serif;
    font-weight 300
    font-size 15px
    color #333
    line-height 1.6
    // lost-utility edit
    border-top 5px solid secondary-color

a
    color primary-color
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd

img
    max-width 100%