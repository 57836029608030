.footer
    lost-utility clearfix
    background secondary-color
    color #fff
    position relative
    font-size 0.9em

    a i
        color white

    .wrapper
        padding 5em 0 2em
        background url('footer-bg.png') no-repeat center 45%/cover

    a
        color #aaa

    .white-trapez-bottom
        position absolute
        top 0px
        width 100%
        border-width 25px 25px 0
        border-style solid
        border-color #fff transparent
        z-index 2

    .footer-logo
        lost-column 4/12
        +below('m')
            lost-column 1/1
        p
            font-weight 300
            line-height 2

        .footer-social-icons
            font-size 2em
            padding-top .5em

    .footer-about, .footer-services
        lost-column 2/12
        color #cbcbcb
        +below('m')
            lost-column 1/2
        +below('xs')
            lost-column 1/1
        ul
            margin 0
            padding 0
            list-style none
            line-height 2
            margin 1em 0

    .footer-contact
        lost-column 4/12
        color #cbcbcb
        line-height 2
        +below('m')
            lost-column 1/1
        .footer-contact-icon-container
            lost-column 1/1
            padding-top 1em

            .footer-contact-icon
                lost-column 1/10
                color #fff
                font-size 1.75em
            .footer-contact-content
                lost-column 9/10
                .display-2
                    color #fff

    .display-1
        padding-top 1rem
        font-weight 800
        text-transform uppercase
        font-size 1.1rem
        color #fff