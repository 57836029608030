.main
    lost-utility clearfix
    padding 2em 0
    color #444
    min-height 400px
    min-height 40vh

.content
    lost-column 1/1
    font-size 1rem
    line-height 1.7
    margin-top .5em

    p
        margin 0 0 1em

    &--two-columns
        & > *
            lost-column 1/3 2 50px
            +below('s')
                lost-column 1/1
        & > div:first-child
            lost-column 2/3 2 50px
            +below('s')
                lost-column 1/1

    &__image img
        width 100%
        +below('s')
            margin-top 3rem
