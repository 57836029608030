.header
    line-height 1

    a
        color inherit


.logo
    lost-column 3/12
    padding 30px 0
    +below('m')
        lost-column 1/1
        text-align center
    +below('s')
        lost-column 2/3
    +below('xs')
        lost-column 1/1

    a
        color inherit
        display block

    img
        max-width 100%

.menu-outer
    height 25px
    background primary-color
    display block
    position relative

    .nav-main
        position absolute
        top -2px
        z-index 9

.menu-inner
    position relative
    width 100%
    border-width 25px 25px 0
    border-style solid
    border-color primary-color transparent
    top 25px
    z-index 2

.contact-information
    lost-column 9/12
    .social-icons
        text-align right
        font-size 1.8rem
        padding 20px 0
        +below('m')
            display none
        i
            padding-left 2px