.topbar
  height 60px
  display block
  +below('m')
    display none
  a
    color inherit
    font-size 0.9em
    padding-right 30px
    i
      padding-right 10px

  .topbar__outer
    height 10px
    background secondary-color
    display block
    position relative

    span
      padding-top 6px
      padding-left 60px
      color #fff
      z-index 3
      position absolute
      &.right
        right 0
        a
          padding-right 0px

  .topbar__inner
    width 100%
    border-width 25px 0px 0 25px
    border-style solid
    border-color secondary-color transparent
    top 10px
    position absolute

.topbar-line
  background secondary-color
  width 50%
  height 35px
  position absolute
  right 0
  +below('m')
    display none