.lead
    min-height 500px
    height 60vh
    z-index 1
    position relative
    background #ccc
    +below('m')
        background url('lead.jpg') no-repeat center 45%/cover
        min-height 200px
        height 20vh

    &--video
        max-height 100%
        max-width 100%
        width 100%
        overflow hidden
        position absolute
        +below('m')
            display none
        video
            width 100%
            +below('l')
                width auto
                height 100%


    .wrapper
        height 100%

    .page-title
        top 50%
        position absolute
        transform: translateY(-50%)
        z-index 3
        background url('pin.png') no-repeat center left
        padding-left 5em
        color #fff
        font-size 1.5em
        min-height 81px
        &.red
            background url('pin-red.png') no-repeat center left
            h1
                color primary-color
            +below('s')
                background none
        +below('s')
            background none
            padding-left 0
            width 100%
        h1
            font-weight 300
            font-size 2.5em
            margin 0
            padding-top 7px
            line-height 1
            +below('m')
                padding-top 10px
                font-size 2em
            +below('s')
                font-size 1.5em
                text-align center
        +below('s')
            span
                display none

    &--small
        height 25vh
        background-position center 35%
        min-height 320px

        +below('s')
            min-height 180px

    .white-trapez-top
        position absolute
        bottom 0
        width 100%
        border-width 0px 25px 25px
        border-style solid
        border-color #fff transparent
        top 25px
        z-index 2
