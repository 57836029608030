.callout
    lost-column 1/1
    margin 1.5rem 0 2rem
    &--inner
        border 1px solid #e2e2e2
        lost-column 1/2
        lost-move 1/4
        color inherit
        padding 10px 20px
        position relative
        transition transform 0.5s ease
        &:hover
            transform scale(0.95)
    &--image
        lost-column 1/3 2 50px
        line-height 0
        img
            vertical-align text-bottom
            max-width 100%
    &--text
        lost-column 2/3 2 50px
        padding 2.5rem 0
        &--link
            background-color primary-color
            color white
            position absolute
            bottom 0
            right 0
            font-size 0.8rem
            padding 0.1em 1.5em
            text-transform uppercase