.nav-main
    lost-column 12/12
    text-align center
    +below('m')
        lost-column 1/1
    +below('s')
        lost-column 1/1
    +below('xs')
        lost-column 1/1

    .nav-items
        +below('l')
            display none

.nav-items
    margin 0
    padding 0

.nav-main .nav-item
    display inline-block
    padding 0
    margin 0
    color #fff
    position relative
    &:first-child
        margin-left 0

    +below('m')
        margin 0 1em

    &--active > .nav-link,
    &--child-active > .nav-link
        color inherit

    &:hover .nav-sub
        display block

.nav-item.nav-item--has-children .nav-link:after
    content: '\f107';
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    margin:0px 0px 0px 10px;
    text-decoration:none;

.nav-link
    display block
    color inherit
    font-weight 800
    text-transform uppercase
    padding 1.28em .8em

.nav-main .nav-sub
    position absolute
    z-index 1
    padding-left 0
    display none
    left 0
    top 52px
    min-width 250px
    box-shadow 0 2px 6px rgba(0, 0, 0, .1)
    background #bc030f
    .nav-item
        display block
        margin 0

    .nav-link
        display block
        margin 0
        padding .8em
        text-align left
        &:after
            content ""
